<template>
    <div>

         <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form label-width="80px">
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：客户信息</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :lg="9">
                                    <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" @click="$refs.AddCompany.addEvent()">添加客户</el-button>
                                    <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" @click="showChageCustomerNameEvent">批量替换业务员</el-button>
                                    <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" @click="banBatchEvent">激活/禁用客户</el-button>
                                    <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini" @click="removeBatchEvent">删除客户</el-button>
                                    <el-button style=" margin-top: 8px; " id="createBill" type="primary" round size="mini" @click="searchCustomerList">搜索查询</el-button>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <vxe-toolbar class="pull-left" style=" height: 42px; " import custom print export ref="xToolbar2">
                                    </vxe-toolbar>
                                </el-col>
                            </el-row>
                        </el-col>

                    </el-row>
                    <el-row>
                        <el-col :lg="24">
                            <vxe-grid ref="xGrid"
                                      size="mini"
                                      :checkbox-config="{range:true}"
                                      :import-config="{
                                        remote: true,
                                        importMethod: this.importMethod,
                                        modes: ['insert'],
                                        type: ['xlsx'],
                                        types: ['xlsx']
                                     }"
                                      border
                                      resizable
                                      show-overflow
                                      keep-source
                                      height="650"
                                      highlight-current-row
                                      :print-config="{}"
                                      :scroll-y="{enabled: false}"
                                      :data="tableDataMain"
                                      :columns="tableColumn"
                                      :mouse-config="{selected: false}">
                                <template #img_default="{ row }"><!-- 图片显示 -->
                                    <div v-for="(imgs,index) in JSON.parse(row.img_list)" :key="index">

                                        <span class="el-upload-list__item-actions">
                                            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(imgs.url)">
                                              <img style="height: 300px;" class="el-upload-list__item-thumbnail" :src="imgs.url">
                                              <!--<i class="el-icon-zoom-in"></i>-->
                                            </span>
                                        </span>
                                    </div>
                                    <vxe-modal v-model="dialogVisible"
                                               width="500px"
                                               title="资料图片"
                                               :esc-closable="false"
                                               :mask-closable="true"
                                               :lock-view="true"
                                               :mask="false"
                                               resize
                                               :draggable="true">
                                        <img width="100%" :src="dialogImageUrl" alt="">
                                    </vxe-modal>
                                    <!--{{row.img_list}}-->
                                    <!--<a v-for="(imgs,index) in JSON.parse(row.img_list)" :key="index">-->
                                        <!--<img :src="imgs.url" style="height: 300px;"/>-->
                                        <!--<el-image style="height: 300px" :src="imgs.url"></el-image>-->
                                       <!-- <a v-for="i in imgs" :key="i">
                                            {{img[i]}}
                                        </a>-->
                                            <!--<img v-if="img" :src="img" style="width: 100px;"/>-->
                                        <!--<el-image style="width: 100px;" v-for="(img,index) in imgs" :key="index" :src="img.url"></el-image>-->
                                    <!--</a>-->
                                </template>
                                <template #operate="{row}">
                                    <!--
                                    <template v-if="$refs.xGrid.isActiveByRow(row)">
                                        <vxe-button icon="el-icon-document-checked" status="primary" title="保存" circle @click="saveRowEvent(row)"></vxe-button>
                                    </template>
                                    <template v-else>
                                    -->
                                    <template>
                                        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="$refs.AddCompany.editRowEvent(row.id)"></vxe-button>
                                    </template>
                                    <vxe-button icon="el-icon-delete" title="删除" circle @click="removeRowEvent(row)"></vxe-button>
                                </template>
                                <template #pager>
                                    <vxe-pager
                                            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                            :current-page.sync="tablePage.currentPage"
                                            :page-size.sync="tablePage.pageSize"
                                            :page-sizes.sync="tablePage.pageSizes"
                                            :total="tablePage.total"
                                            @page-change="handlePageChange">
                                    </vxe-pager>
                                </template>
                            </vxe-grid>
                        </el-col>
                    </el-row>
                </el-form>
        </el-main>
        <el-dialog width="1220px"  :title="isErrorTitle ? '失败' : '导入数据'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <vxe-grid
                    border
                    resizable
                    show-overflow
                    height="400"
                    ref="xGridImport"
                    :columns="tableColumnImport"
                    :data="tableDataImport"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div slot="footer" class="dialog-footer">
                <el-button @click="openExportEvent = false">导 出</el-button>
                <template v-if="isErrorTitle">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </template>

                <el-button type="primary" @click="saveImport" v-show="!isErrorTitle">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog width="320px"  title="批量替换" :visible.sync="changeCustomerNameDialogFormVisible" :close-on-click-modal="false">
            <el-select ref="selectCustomer" v-model="changeCustomerId" default-first-option
                       @focus="$_salesManListFilter"
                       :filter-method="$_salesManListFilter"
                       clearable filterable placeholder="可搜索查询">
                <el-option
                        v-for="item in $store.getters.getAccountList"
                        :key="item.id"
                        :label="item.nick_name"
                        :value="item.id">
                </el-option>
            </el-select>
            <div slot="footer" class="dialog-footer">
                <el-button @click="changeCustomerNameDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="changeCustomerNameEvent">确 定</el-button>
            </div>
        </el-dialog>
        <AddCompany ref="AddCompany" :page-type="pageType"></AddCompany>
    </div>
</template>
<script>
    import {filterRender} from "@/utils/gird-filter";

    import AddCompany from "../../components/AddCompany";
    export default {
        components: {AddCompany},
        data() {
            return {
                //图片start
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                //图片end
                pageType:'客户信息',
                changeCustomerNameDialogFormVisible:false,
                uuid:'',//导入的uuid
                isErrorTitle:'',
                account:[],
                tableColumn: [
                    {type: 'checkbox', width: 50},
                    {field:'operate',title: '操作', width: 80, slots: {default: 'operate'}},
                    //,visible:false
                    {
                        field:'company_uuid', width: 100, title: 'UUID', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplier/searchHeard')
                       }
                   },
                    {field:'id', width: 80 ,title: '客户编号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    {field:'sales_man', width: 70 ,title: '业务员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    {field:'customer_name', width: 240 ,title: '客户名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },{field:'state', width: 60 ,title: '状态' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    {field:'create_date', width: 170 ,title: '建档日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini',type:'date'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    {field:'linkman', width: 70 ,title: '联系人' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'phone', width: 130 ,title: '联系电话' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'img_list', width: 330 ,title: '资料图片',
                        slots:{
                            default: 'img_default',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                        }
                    },
                    { field:'month_task', width: 70 ,title: '月任务' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'year_task', width: 70 ,title: '年任务' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'certificate_code', width: 100 ,title: '法人代码' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'certificate_phone', width: 100 ,title: '法人电话' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'deposit_bank', width: 100 ,title: '开户银行' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'tax_id', width: 100 ,title: '税务登记号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },

                    { field:'address', width: 100 ,title: '地址' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'remark', width: 100 ,title: '备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                    { field:'area',visible:false, width: 100 ,title: '地区' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/customers/searchHeard')
                       }
                   },
                ],
                tableColumnImport:[
                    { field:'id', width: 80 ,title: '客户编码'}
                    ,{ field:'error', width: 250 ,title: '系统提示',slots: {
                            default: ({ row}) => {
                                return [
                                    <b style="color: blue">{row.error}</b>
                                ]
                           },
                       }
                   },
                    { field:'sales_man', width: 70 ,title: '业务员'
                   },
                    { field:'customer_name', width: 240 ,title: '客户名称'
                   },
                    { field:'certificate_code', width: 100 ,title: '法人代码'
                   },
                    { field:'linkman', width: 100 ,title: '联系人'
                   },
                    { field:'phone', width: 100 ,title: '联系电话'
                   },
                    { field:'create_date', width: 150 ,title: '建档日期'
                   },
                    { field:'address', width: 100 ,title: '地址'
                   },{ field:'remark', width: 100 ,title: '备注'
                   }              ],
                tableDataImport:[],
                tableDataMain:[],
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [50,100,500,1000,2000,20000]
               },
                filterData: {},
                sortData: {id:'asc'},
                activeName:'first',
                remvoeIds:[],
                banIds:[],
                changeCustomerId:'',
                changeCustomerNamesId:[],
           }
       },
        methods: {
            //图片start
            handlePictureCardPreview(url) {
                this.dialogImageUrl = url;
                this.dialogVisible = true;
            },
            //图片end
            accountEvent(query){
                this.$axios({
                    method: 'post',
                    url: '/admin/account/getAccountListByName',
                    params: {
                        keyword: query
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.account = response.data.page.list
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            openExportEvent () {
                this.$refs.xGridImport.openExport()
           },
            showChageCustomerNameEvent(){
                const $grid = this.$refs.xGrid
                const selectRecords =  $grid.getCheckboxRecords();
                if(selectRecords.length > 0 ){
                    this.changeCustomerNameDialogFormVisible = true
               }else{
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }
           },
            changeCustomerNameEvent(){
                const $grid = this.$refs.xGrid
                const selectRecords =  $grid.getCheckboxRecords();
                this.changeCustomerNamesId = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.changeCustomerNamesId.push(selectRecords[i].id)
               }
                this.$axios({
                    method: 'post',
                    url: '/admin/customers/customerNameBatchVue',
                    params:{
                        changeCustomerId:this.changeCustomerId,
                        changeCustomerNamesId:this.changeCustomerNamesId
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.$message({
                            showClose: true,
                            message: '批量修改成功',
                            type: 'success'
                       });
                        this.getCustomerList();
                        this.changeCustomerNameDialogFormVisible = false
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });

           },
            banBatchEvent () {
                const $grid = this.$refs.xGrid
                const selectRecords =  $grid.getCheckboxRecords();
                this.banIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.banIds.push(selectRecords[i].id)
               }
                if(selectRecords.length > 0 ){
                    this.$XModal.confirm('您确定要激活/禁用 '+this.banIds.length+' 个客户?').then(type => {
                        if (type === 'confirm') {
                            //传送删除动作
                            this.$axios({
                                method: 'post',
                                url: '/admin/customers/banBatchVue',
                                params: {
                                    banIds: this.banIds
                               }
                           }).then((response) => {          //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量删除成功',
                                        type: 'success'
                                   });
                                    this.getCustomerList();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   });
               }else{
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }

           },
            removeBatchEvent(){
                const $grid = this.$refs.xGrid
                const selectRecords =  $grid.getCheckboxRecords();
                this.remvoeIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.remvoeIds.push(selectRecords[i].id)
               }
                if(selectRecords.length > 0 ){
                    this.$XModal.confirm('您确定要删除 '+this.remvoeIds.length+' 条数据?').then(type => {
                        if (type === 'confirm') {
                            //传送删除动作
                            this.$axios({
                                method: 'post',
                                url: '/admin/customers/deleteBatchVue',
                                params: {
                                    remvoeIds: this.remvoeIds
                               }
                           }).then((response) => {          //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量删除成功',
                                        type: 'success'
                                   });
                                    this.getCustomerList();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   });
               }else{
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }

           },
            removeRowEvent (row) {
                this.$XModal.confirm('您确定要删除该数据?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method:'post',
                            url:'/admin/customers/deleteVue',
                            params: {
                                id:row.id
                           }
                       }).then((response) =>{          //这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.getCustomerList();
                           }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) =>{
                            console.log(error)
                       });

                   }
               })
           },
            searchCustomerList(){
                this.tablePage.currentPage = 1;
                this.getCustomerList();
           },
            getCustomerList(){
                this.$axios({
                    method:'POST',
                    url:'/admin/customers/search',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                   }
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           },
            saveImport(){
                const loading = this.$loading({
                    lock: true,
                    text: '数据录入中，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method:'post',
                    url:'/admin/customers/saveImportExcel',
                    params: {
                        uuid:this.uuid
                   }
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                       });
                        this.dialogFormVisible = false
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
                    this.getCustomerList();
               }).catch((error) =>{
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            downFileEvent(){
                window.open('/template/Customers.xlsx','_blank')
           },
            importMethod ({ file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method:'post',
                    url:'/admin/customers/importExcel',
                    data: formBody,
               }).then((response) =>{          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if(response.status === 200 && response.data.state == 'ok'){
                        this.isErrorTitle=response.data.isError
                        if(response.data.isError == true){
                            this.tableDataImport=response.data.customerListError
                       }else{
                            this.tableDataImport=response.data.customerList

                       }

                        this.uuid=response.data.uuid
                        this.dialogFormVisible = true;

                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) =>{
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                   });
                    console.log(error)
               });
           },
            handlePageChange ({ currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getCustomerList()
           },
            /*saveRowEvent () {
                const $grid = this.$refs.xGrid
                $grid.clearActived().then(() => {
                    this.gridOptions.loading = true
                    setTimeout(() => {
                        this.gridOptions.loading = false
                        this.$XModal.message({ content: '保存成功！test', status: 'success'})
                   }, 300)
               })
           },*/

       },
        created () {

            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2);
                this.getCustomerList();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    /deep/ .el-form-item__error{
        position: initial !important;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
    /deep/ .vxe-input--panel{
        position: fixed !important;
        left:auto;
   }
    /deep/ .el-input-number .el-input__inner{
        text-align: initial !important;
   }

    .el-form-item__content .el-input-group{
        vertical-align: initial;
   }

    /deep/ .vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell{
        max-height: initial;
    }

</style>
